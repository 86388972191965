import React from 'react';
import { Route } from 'react-router-dom';

const AppInstall = React.lazy(() => import('pages/etc/AppInstall/AppInstall'));
const AcademyBillingInfo = React.lazy(
  () => import('pages/etc/AcademyBillingInfo/AcademyBillingInfo'),
);
const HelloWorld = React.lazy(() => import('pages/etc/HelloWorld/HelloWorld'));
const Tester = React.lazy(() => import('pages/etc/Tester/Tester'));
const PwReset = React.lazy(() => import('pages/etc/PwReset/PwReset'));

// 이 페이지들에서는 pageStore가 token을 참조하지 않는다.
// 외부 브라우저에서 바로 표시되어야 하는 페이지들
const RouteListEtc = (
  <Route path="etc/">
    <Route path="appInstall" element={<AppInstall />} />
    <Route path="academyBillingInfo" element={<AcademyBillingInfo />} />
    <Route path="helloWorld" element={<HelloWorld />} />
    <Route path="tester" element={<Tester />} />
    <Route path="pwReset" element={<PwReset />} />
  </Route>
);
export default RouteListEtc;
