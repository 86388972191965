export const pagesWithoutMyInfo = [
  '/user/start',
  '/user/welcome',
  '/user/signIn',
  '/user/signUp',
  '/user/agreement',
  '/user/agreementDetail',
  '/user/selectRole',
  '/user/verifyPhone',
  '/user/verifyPhoneOfParent',
  '/user/findIdPw',
  '/etc/appInstall',
  '/etc/academyBillingInfo',
  '/etc/pwReset',
];
